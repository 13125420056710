import { render, staticRenderFns } from "./Spinners.vue?vue&type=template&id=670c5357&"
import script from "./Spinners.vue?vue&type=script&lang=js&"
export * from "./Spinners.vue?vue&type=script&lang=js&"
import style0 from "./Spinners.vue?vue&type=style&index=0&id=670c5357&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports